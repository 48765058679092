.straw {
	.straw-item {
		transition: background .3s ease-in-out;
		
		&:hover {
			background: #e9ecef;
		}
		&.light {
			color: white;
		}

		input {
			width: 100%;
			outline: none;
			border: none;
			padding: 0;
			background: transparent;
		}

		.btn-color-picker {
			position: relative;
			padding: .2rem;
			margin-right: .5rem;
			.btn-color {
				// border-radius: .2rem;
				// border: 1px solid black;
				width: 1rem;
				height: 1rem;
				// background-color: red
			}
		}
	}
}

.drawing-result {
	.drawing-result-item {
		overflow: hidden;
	}
	.item-enter {
		opacity: 0;
	}
	.item-enter::after {
		display: block;
		content: '';
		position: absolute;
		left: -250px;
		top: calc(50% - 250px);
		width: 500px;
		height: 500px;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0.4);
		transform: scale(0);
	}
	.item-enter-active {
		opacity: 1;
		transition-property: opacity;
		transition-duration: .5s;
		transition-timing-function: ease;
	}
	.item-enter-active::after {
		display: block;
		content: '';
		position: absolute;
		left: -250px;
		top: calc(50% - 250px);
		width: 500px;
		height: 500px;
		border-radius: 50%;
		background-color: rgba(0, 0, 0, 0);
		transform: scale(2.5);
		transition-property: transform, background-color;
		transition-duration: .5s, .5s;
		transition-timing-function: ease, ease;
	}
}